import { Fragment } from 'react';
import { Flex, Image } from 'rebass/styled-components';
import { Link } from "gatsby"
import HeaderContainer from "./HeaderContainer"
import * as React from "react"

const baseUrl = "./Logo/";

const DefaultHeader = () => (
  <HeaderContainer>
    <Flex
      flexWrap="wrap"
      justifyContent="space-between"
      alignItems="center"
      p={3}
    >
      <Fragment>
        <Link to="/">
          <Image
            src={require( `${ baseUrl + 'burritoAppsLogoWhite.png' }` )}
            width="50px"
            alt="BurritoApps Logo"
            style={{
              cursor: 'pointer',
            }}
          />
        </Link>
      </Fragment>
    </Flex>
  </HeaderContainer>
);

export default DefaultHeader;
